import React, { useState, useEffect } from "react";
import style from "./GoalDetail.module.css";
import { baseurl } from "../Url";

function GoalDetails() {
  const [goals, setGoals] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("USERID");
    const learningId = localStorage.getItem("newLearningId");

    if (!userId || !learningId) {
      setError("User ID and Learning ID are required.");
      setLoading(false);
      return;
    }

    const fetchUserGoals = async () => {
      try {
        const response = await fetch(`${baseurl}/list/usergoals-check`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            learning_id: learningId,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user goals.");
        }

        const data = await response.json();

        if (data.status) {
          setGoals(data);
        } else {
          setError(data.message || "No goals found.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserGoals();
  }, []);

  // Helper to render nested objects and arrays
  const renderNestedArray = (nestedArray) => {
    return nestedArray.map((item, index) => (
      <div key={index}>
        {Object.entries(item).map(([key, value], subIndex) => (
          <div className={style.padded} key={subIndex}>
            <strong>{key} : </strong>
            <ul>
              {Array.isArray(value) ? (
                value.map((v, i) => <li key={i}>{v}</li>)
              ) : (
                <li>{value}</li>
              )}
            </ul>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className={style.goalDetails}>
  <h1>User Goals</h1>
  {loading && <p>Loading...</p>}
  {error && <p className={style.error}>{error}</p>}
  {goals && (
    <>
      {goals.learning_objective?.length > 0 ||
      goals.learning_objective_goal?.length > 0 ||
      goals.user_status?.length > 0 ||
      goals.learning_preference?.length > 0 ||
      goals.learning_constraints?.length > 0 ? (
        <div className={style.goalContainer}>
          {goals.learning_objective?.length > 0 && (
            <div className={style.box}>
              <h3>Your Goals & Objectives</h3>
              {renderNestedArray(goals.learning_objective)}
            </div>
          )}
          {goals.learning_objective_goal?.length > 0 && (
            <div className={style.box}>
              <h3>Your Career or Application-Oriented Goals</h3>
              {renderNestedArray(goals.learning_objective_goal)}
            </div>
          )}
          {goals.user_status?.length > 0 && (
            <div className={style.box}>
              <h3>Your Background</h3>
              {renderNestedArray(goals.user_status)}
            </div>
          )}
          {goals.learning_preference?.length > 0 && (
            <div className={style.box}>
              <h3>Your Preferences</h3>
              {renderNestedArray(goals.learning_preference)}
            </div>
          )}
          {goals.learning_constraints?.length > 0 && (
            <div className={style.box}>
              <h3>Your Constraints</h3>
              {renderNestedArray(goals.learning_constraints)}
            </div>
          )}
        </div>
      ) : (
        <p>
          You haven't set up any goals yet. It looks like you've skipped all
          sections.
          <br />
          To get the most out of your experience, please take a moment to
          set up your goals.
        </p>
      )}

      {/* Check if more than one section has an empty array, but not all sections */}
      {[
        goals.learning_objective,
        goals.learning_objective_goal,
        goals.user_status,
        goals.learning_preference,
        goals.learning_constraints,
      ]
        .filter((section) => section?.length === 0).length > 1 &&
      // Ensure not all sections are empty
      !(
        goals.learning_objective?.length === 0 &&
        goals.learning_objective_goal?.length === 0 &&
        goals.user_status?.length === 0 &&
        goals.learning_preference?.length === 0 &&
        goals.learning_constraints?.length === 0
      ) && (
        <p className={style.warning}>
          Your goals are not completely set up. Please reset your goals to complete more sections to fully set up your goals.
        </p>
      )}
    </>
  )}
</div>

  );
}

export default GoalDetails;
