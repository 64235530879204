import React, { useEffect, useState } from "react";
import style from "./Calendar.module.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Loader from "../Loder/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { css } from "@emotion/react";
import { baseurl } from "../Url";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

const Calendar = () => {
  const [title, setTitle] = useState(""); // State to store event title
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [calendarItem, setCalendarItem] = useState({});
  const [titleSecond, setTitleSecond] = useState(""); // State to store event title
  const [descriptionSecond, setDescriptionSecond] = useState("");
  const [startDateSecond, setStartDateSecond] = useState("");

  const [startTimeSecond, setStartTimeSecond] = useState("");

  const [endDateSecond, setEndDateSecond] = useState("");

  const [endTimeSecond, setEndTimeSecond] = useState("");
  const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);

  const [popup, setPopup] = useState(false);
  const [events, setEvents] = useState([]);
  const USERID = localStorage.getItem("USERID");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [calendarID, setCalendarID] = useState("");
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState(false);

  const [itemID, setItemID] = useState("");
  const [deleteItem, setDeleteItem] = useState(false);
  const [edit, setEdit] = useState(false);
  const mentor = localStorage.getItem("mentor");
  const Timezone = localStorage.getItem("timezone");
  const [validTimezone, setValidTimezone] = useState("");

  useEffect(() => {
    if (Timezone !== undefined && Timezone !== null) {
      const isValidTimezone =
        Intl.DateTimeFormat(undefined, { timeZone: Timezone }).resolvedOptions()
          .timeZone !== "UTC";
      setValidTimezone(isValidTimezone);
    }
  }, [Timezone]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
    // editor.ui.view.editable.element.style.Height = "300px";
  };
  const handleEditorChangeSecond = (event, editor) => {
    const data = editor.getData();
    setDescriptionSecond(data);
    // editor.ui.view.editable.element.style.Height = "300px";
  };

  useEffect(() => {
    setTitleSecond(calendarItem.Title);
    setDescriptionSecond(calendarItem.Description);

    setStartDateSecond(
      calendarItem && calendarItem.StartDateTime
        ? new Date(calendarItem.StartDateTime.$date).toISOString().substr(0, 10)
        : ""
    );

    setEndDateSecond(
      calendarItem && calendarItem.EndDateTime
        ? new Date(calendarItem.EndDateTime.$date).toISOString().substr(0, 10)
        : ""
    );

    setStartTimeSecond(
      calendarItem && calendarItem.StartDateTime
        ? new Date(calendarItem.StartDateTime.$date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
        : ''
    );
    setEndTimeSecond(
      calendarItem && calendarItem.EndDateTime
        ? new Date(calendarItem.EndDateTime.$date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
        : ''
    );
    
    

  }, [calendarItem]);
  useEffect(() => {
    // console.log(startDateSecond);
  }, [calendarItem]);

  const handleDateClick = (arg) => {
    const clickedDate = new Date(arg.date);
    const currentDate = new Date();
    const endDate = new Date(arg.view.currentEnd - 1);
    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    if (clickedDate >= currentDate && clickedDate <= endDate) {
      setPopup(true);
      setStartDate(arg.dateStr);
    } else {
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(`${endDate}T${endTime}`);

    if (title && startDateTime && endDateTime) {
      const newEvent = {
        title,
        description,
        start: startDateTime.toISOString(),
        end: endDateTime.toISOString(),
      };
      setEvents([...events, newEvent]);

      // Clear form fields and close the popup
      // setTitle('');
      // setDescription('');
      // setStartDate('');
      // setStartTime('');
      // setEndDate('');
      // setEndTime('');
      // setPopup(false);

      // Call the function to add the event to the calendar
      calendarItemAdd(newEvent);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    const startDateTime = new Date(`${startDateSecond}T${startTimeSecond}`);
    //      startDateTime.setMinutes(startDateTime.getMinutes() - startDateTime.getTimezoneOffset());
    // const startDateTimeUTC = startDateTime.toISOString();

    const endDateTime = new Date(`${endDateSecond}T${endTimeSecond}`);
    // endDateTime.setMinutes(endDateTime.getMinutes() - endDateTime.getTimezoneOffset());
    // const endDateTimeUTC = endDateTime.toISOString();

    if (titleSecond && startDateTime && endDateTime) {
      const newEvent = {
        titleSecond,
        descriptionSecond,
        start: startDateTime,
        end: endDateTime,
      };
      eventUpdate(newEvent);
      console.log(newEvent);
    }
  };

  const handleEventClick = (info) => {
    // Open the custom event entry popup when an event is clicked
    const clickedItemID = info.event.extendedProps.CalendarItemID;

    setItemID(clickedItemID);
    setView(true);
    individualItem(clickedItemID);
  };

  async function calendarItemAdd(eventData) {
    console.log("add");
    // eventData contains the event data to be added to the calendar
    await fetch(`${baseurl}/calendaritem/add`, {
      method: "POST",
      body: JSON.stringify({
        CalendarID: calendarID,
        login_user: USERID,
        Title: eventData.title,
        ItemType: "Task",
        Description: eventData.description,
        StartDateTime: eventData.start,
        EndDateTime: eventData.end,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.status === true) {
          calendarGet();
          setPopup(false);
          setTitle("");
          setDescription("");
          setStartDate("");
          setStartTime("");
          setEndDate("");
          setEndTime("");
          setLoading(true);
        }
      });
  }

  async function calendarAdd() {
    await fetch(`${baseurl}/calendar/add`, {
      method: "POST",
      body: JSON.stringify({
        MemberID: USERID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCalendarID(data.data[0].CalendarID);
        if (data.status === true) {
          calendarGet();
        }
      });
  }

  async function calendarGet() {
    await fetch(`${baseurl}/calendaritem/get`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        is_mentor: mentor,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        const formattedEvents = data.data.map((item) => ({
          title: item.Title,
          start: item.StartDateTime.$date, // Assuming StartDateTime is correctly formatted
          end: item.EndDateTime.$date, // Assuming EndDateTime is correctly formatted
          description: item.Description,
          CalendarItemID: item.CalendarItemID,
          // Add other event properties as needed
        }));

        // Update the events state with the formatted data
        setEvents(formattedEvents);
        setLoading(false);
      });
  }

  async function individualItem(calendarItemID) {
    await fetch(`${baseurl}/calendaritem/get/individual`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: calendarItemID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCalendarItem(data.data[0]);
      });
  }

  async function eventDelete() {
    await fetch(`${baseurl}/calendaritem/delete`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: itemID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDeleteItem(false);
          calendarGet();
          setLoading(true);
        }
      });
  }
  async function eventUpdate(event) {
    console.log(event);
    await fetch(`${baseurl}/calendaritem/update`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: itemID,
        Title: event.titleSecond,
        Description: event.descriptionSecond,
        StartDateTime: event.start,
        EndDateTime: event.end,
        Status: "inprogress",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEdit(false);
          setPopup(false);
          calendarGet();
          setLoading(true);
        }
      });
  }
  useEffect(() => {
    calendarAdd();
  }, [key]);

  const handleEndDateChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate = startDate !== "" ? new Date(startDate) : null;
    if (selectedStartDate && selectedEndDate < selectedStartDate) {
      console.error("End date cannot be less than start date");
      e.target.value = "";
      setEndDate("");
    } else {
      setEndDate(e.target.value);
    }
  };
  const handleStartDateSecondChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      console.error("Selected date is in the past");
      e.target.value = "";
      setStartDateSecond("");
    } else {
      setStartDateSecond(e.target.value);
    }
  };
  const handleEndDateSecondChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate =
      startDateSecond !== "" ? new Date(startDateSecond) : null;

    if (selectedStartDate && selectedEndDate < selectedStartDate) {
      console.error("End date cannot be less than start date");
      e.target.value = "";
      setEndDateSecond("");
    } else {
      setEndDateSecond(e.target.value);
    }
  };

  const handlestartTimeChange = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(`${startDate}T${selectedStartTime}`);

    if (selectedDateTime <= currentDateTime) {
      setStartTime("");
    } else {
      setStartTime(selectedStartTime);
    }
  };
  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;

    if (startDate === endDate && selectedEndTime <= startTime) {
      setEndTime("");
    } else {
      setEndTime(selectedEndTime);
    }
  };

  const handleStartTimeSecondChange = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(
      `${startDateSecond}T${selectedStartTime}`
    );

    if (selectedDateTime <= currentDateTime) {
      setStartTimeSecond("");
    } else {
      setStartTimeSecond(selectedStartTime);
    }
  };
  const handleEndTimeSecondChange = (e) => {
    const selectedEndTime = e.target.value;
    if (
      startDateSecond === endDateSecond &&
      selectedEndTime <= startTimeSecond
    ) {
      setEndTimeSecond("");
    } else {
      setEndTimeSecond(selectedEndTime);
    }
  };

  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      {loading ? <Loader /> : ""}
      {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )}
      {popup ? (
        <>
          {!edit ? (
            <>
              <div
                className={style.ProfileOverlay}
                onClick={() => {
                  setPopup(false);
                }}
              ></div>
              <div className={style.AddressSection}>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    placeholder="Event Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        required
                        placeholder="Start Date"
                        readOnly
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className={style.TimeSecond}>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        id="endDate"
                        required
                        placeholder="End Date"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Time</label>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        required
                        placeholder="Start Time"
                        value={startTime}
                        onChange={handlestartTimeChange}
                      />
                    </div>

                    <div className={style.TimeSecond}>
                      <label>End Time</label>
                      <input
                        type="time"
                        name="endTime"
                        id="endTime"
                        required
                        placeholder="End Time"
                        value={endTime}
                        onChange={handleEndTimeChange}
                      />
                    </div>
                  </div>
                  <div className={style.Description}>
                    <label>Description</label>
                    <div className={style.Editor}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onChange={handleEditorChange}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "100px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                          ],
                          alignment: {
                            options: ["left", "center", "right"],
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.FormButton}>
                    <button
                      disabled={
                        endTime === "" ||
                        startTime === "" ||
                        endDate === "" ||
                        title === ""
                      }
                      type="submit"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPopup(false);
                        setTitle("");
                        setDescription("");
                        setStartDate("");
                        setStartTime("");
                        setEndDate("");
                        setEndTime("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.ProfileOverlay}
                onClick={() => {
                  setPopup(false);
                }}
              ></div>
              <div className={style.AddressSection}>
                <form onSubmit={handleUpdate}>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    placeholder="Event Title"
                    value={titleSecond}
                    onChange={(e) => setTitleSecond(e.target.value)}
                  />

                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        required
                        placeholder="Start Date"
                        value={startDateSecond}
                        onChange={handleStartDateSecondChange}
                      />
                    </div>
                    <div className={style.TimeSecond}>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        id="endDate"
                        required
                        placeholder="End Date"
                        value={endDateSecond}
                        onChange={handleEndDateSecondChange}
                      />
                    </div>
                  </div>
                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Time</label>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        required
                        placeholder="Start Time"
                        value={startTimeSecond}
                        onChange={handleStartTimeSecondChange}
                      />
                    </div>

                    <div className={style.TimeSecond}>
                      <label>End Time</label>
                      <input
                        type="time"
                        name="endTime"
                        id="endTime"
                        required
                        placeholder="End Time"
                        value={endTimeSecond}
                        onChange={handleEndTimeSecondChange}
                      />
                    </div>
                  </div>
                  <div className={style.Description}>
                    <label>Description</label>
                    <div className={style.Editor}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={descriptionSecond}
                        onChange={handleEditorChangeSecond}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "100px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                          ],
                          alignment: {
                            options: ["left", "center", "right"],
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.FormButton}>
                    <button
                      disabled={
                        startDateSecond === "" ||
                        endDateSecond === "" ||
                        titleSecond === "" ||
                        startTimeSecond === "" ||
                        endTimeSecond === ""
                      }
                      type="submit"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPopup(false);
                        setTitleSecond("");
                        setDescriptionSecond("");
                        setStartDateSecond("");
                        setStartTimeSecond("");
                        setEndDateSecond("");
                        setEndTimeSecond("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      ) : (
        ""
      )}
      {view ? (
        <>
          <div
            className={style.ProfileOverlay}
            onClick={() => {
              setView(false);
            }}
          ></div>
          <div className={style.ViewPopup}>
            <div className={style.Icons}>
              {calendarItem.ItemType !== "session event" && (
                <span className={style.EditIcons}>
                  <span
                    onClick={() => {
                      setPopup(true);
                      setEdit(true);
                      setView(false);
                    }}
                  >
                    <GrEdit />
                  </span>
                  <span
                    onClick={() => {
                      setDeleteItem(true);
                      setView(false);
                    }}
                  >
                    <RiDeleteBin6Line />
                  </span>
                </span>
              )}
            </div>
            <div className={style.Content}>
              <div className={style.Title}>
                <p>{calendarItem.Title}</p>
              </div>
              <div className={style.start}>
                {" "}
                {calendarItem.StartDateTime && calendarItem.EndDateTime && (
                  <>
                    <p>
                      {new Date(
                        calendarItem.StartDateTime.$date
                      ).toLocaleDateString(undefined, {
                        timeZone: Timezone,
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      })}
                      {" - "}
                      {new Date(
                        calendarItem.EndDateTime.$date
                      ).toLocaleDateString(undefined, {
                        timeZone: Timezone,
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deleteItem ? (
        <>
          <div
            className={style.ProfileOverlay}
            onClick={() => {
              setDeleteItem(false);
            }}
          ></div>
          <div className={style.DeletePopup}>
            <div className={style.DeleteText}>
              <p>Are you sure you want to Delete?</p>
            </div>
            <div className={style.DeleteButton}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  eventDelete();
                }}
              >
                YES
              </button>
              <button
                onClick={() => {
                  setDeleteItem(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <h1>My Calendar</h1>
      <div className="calendar-container">
        <style>
          {`
          .calendar-container .fc-header-toolbar {
            font-size: 16px; /* Adjust the font size as needed */
            font-weight: bold; /* Adjust the font weight as needed */
          }
          
          @media (max-width: 600px) {
            .calendar-container .fc-header-toolbar {
              font-size: 12px; /* Adjust the font size for smaller screens */
            }
            .fc .fc-toolbar {
              align-items:flex-start; 
              display: flex;
              justify-content: space-between;
              flex-direction: column;
          }
          }
        `}
        </style>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          headerToolbar={{
            start: "prev,next",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          timeZone="local"
          height="600px"
          aspectRatio={2}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
          }}
          showNonCurrentDates={false}
        />
      </div>
    </div>
  );
};

export default Calendar;
