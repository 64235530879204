import React, { useEffect, useState, useRef } from "react";
import style from "./Activity.module.css";
import { baseurl } from "../Url";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LeftArrow from "../../assests/LeftArrow.png";
import Loader from "../Loder/Loader";
import ChatPopup from "../ChatPopup/ChatPopup";
import { BsRobot } from "react-icons/bs";
import { IoDocumentAttach } from "react-icons/io5";

function Activity() {
  const location = useLocation();
  const navigate = useNavigate();

  const [activityDetails, setActivityDetails] = useState([]);
  const key = JSON.parse(localStorage.getItem("access-token"));
  const mentor = localStorage.getItem("mentor");
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const main_topic_id = localStorage.getItem("main_topic_id");
  const UserName = localStorage.getItem("username");
  const [commentId, setCommentId] = useState("");
  const [activityComments, setActivityComments] = useState({});
  const [assessmentCommentText, setAssessmentCommentText] = useState("");
  const [aiID, setAiID] = useState(null);
  const [isChatPopupVisible, setChatPopupVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [singleTopic, setSingleTopic] = useState(null);
  const [learningID, setLearningID] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [topicNo, setTopicNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activityTextEdit, setActivityTextEdit] = useState(false);
  const [activityEditUrl, setActivityEditUrl] = useState("");
  const [activityUrlEdit, setActivityUrlEdit] = useState(false);
  const [activityPopup, setActivityPopup] = useState(false);
  const [activityTitleEdit, setActivityTitleEdit] = useState("");
  const [activityID, setActivityID] = useState("");
  const [contentId, setContentId] = useState("");
  const [roomID, setRoomID] = useState(false);
  const [bot, setBot] = useState(false);
  const [comment, setComment] = useState(false);
  // const [assesmentComentData, setAssessmentComentData] = useState([]);
  const [mappedLessons, setMappedLessons] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      console.log(`You selected the file: ${selectedFile.name}`); // Check this log in the console
    }
  };

  const [mainTopicID, setMainTopicID] = useState(null);

  const openChatPopup = () => setChatPopupVisible(true);
  const closeChatPopup = () => setChatPopupVisible(false);

  const commentEditorChange = (event, editor, activityId) => {
    const data = editor.getData();
    setActivityComments((prevComments) => {
      const updatedComments = {
        ...prevComments,
        [activityId]: data,
      };
      console.log(updatedComments); // Check if comment data is being updated
      return updatedComments;
    });
  };

  const commentDivRef = useRef(null);
  useEffect(() => {
    if (comment && commentDivRef.current) {
      commentDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [comment]);

  const inputRef = useRef(null);
  useEffect(() => {
    if (bot && inputRef.current) {
      inputRef.current.focus();
    }
  }, [bot]);

  async function activityGet() {
    if (!singleTopic || !singleTopic.id) return; // Add this check to prevent errors

    console.log("Fetching activity details");
    setLoading(true); // Start loader

    try {
      const response = await fetch(`${baseurl}/activity/get`, {
        method: "POST",
        body: JSON.stringify({
          org_id: OrgID,
          topic_id: singleTopic.id,
          ai_id: aiID,
          user_id: USERID,
          main_topic_id: mainTopicID,
          learning_id: learningID,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      });

      const data = await response.json();
      setActivityDetails(data.data || []);

      setTimeout(() => {
        setLoading(false); // Stop loader after delay
      }, 1500);
    } catch (error) {
      console.error("Error fetching activity data:", error);

      setTimeout(() => {
        setLoading(false); // Stop loader in case of error
      }, 1500);
    }
  }
  const assessmentCommentEditorChange = (event, editor) => {
    const data = editor.getData();
    setAssessmentCommentText(data);
  };

  const [activityeditTextData, setActivityeditTextData] = useState("");
  const handleactivityUpdateChange = (event, editor) => {
    const data = editor.getData();
    setActivityeditTextData(data);
  };

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayContent, setOverlayContent] = useState({ url: "", type: "" });

  const openOverlay = (url, type) => {
    setOverlayContent({ url, type });
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setOverlayContent({ url: "", type: "" });
  };

  async function commentAdd(activityid, contentid, comment) {
    const formData = new FormData();
    formData.append("activity_id", activityid);
    formData.append("content_id", contentid);
    formData.append("content", comment);
    formData.append("created_by_id", USERID);
    formData.append("created_by_name", UserName);
    formData.append("file", file);

    try {
      const response = await fetch(`${baseurl}/comment/add`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${key}`,
        },
      });

      const data = await response.json();
      console.log("Response from server:", data);

      if (data.status === true) {
        activityGet();
        setFile(null);
        setActivityComments((prevComments) => ({
          ...prevComments,
          [contentid]: "",
        }));
      }
    } catch (error) {
      console.error("Error while posting comment:", error);
    }
  }

  useEffect(() => {
    if (activityDetails.length > 0) {
      const initialComments = {};
      activityDetails[0]?.contents.forEach((activity) => {
        initialComments[activity.content_id] = ""; // Initialize with empty strings
      });
      setActivityComments(initialComments);
    }
  }, [activityDetails]);

  useEffect(() => {
    if (location.state) {
      setAiID(location.state.aiID);
      setSingleTopic(location.state.singleTopic);
      setMappedLessons(location.state.mapped);
      setLearningID(location.state.learning);
      setMainTopicID(location.state.mainID);
      setTopicNo(location.state.topicNo);
      setUniqueId(location.state.uniqueId);
      setRoomID(location.state.roomID);
    }
  }, [location.state]);

  useEffect(() => {
    if (aiID && singleTopic && learningID && mainTopicID) {
      activityGet();
    }
  }, [aiID, singleTopic, learningID, mainTopicID]);

  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : (
        <div className={style.ActivityBody}>
          <div className={style.header}>
            <div className={style.headerIcon}>
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={LeftArrow}
                alt="Left"
              />
            </div>
            <div className={style.headerText}>
              {/* <span>Topic {topicNo}</span> */}
              <h4>Activity</h4>
            </div>
          </div>
          <div className={style.ActivityDiv}>
            <div className={style.ActivityContext}>
              <div className={style.contextBox}>
                {activityDetails && activityDetails.length > 0 ? (
                  activityDetails[0].contents ? (
                    activityDetails[0].contents.map((activities, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={style.ActivityHeading}>
                            <p>
                              {index + 1} . {activities.title}
                            </p>
                            <div className={style.ActivityHeadingIcons}>
                              {mentor === "true" ? (
                                <>
                                  {["text", "url"].includes(activities.type) ? (
                                    <>
                                      <span
                                        onClick={() => {
                                          if (activities.type === "text") {
                                            setActivityeditTextData(
                                              activities.text
                                            );
                                            setActivityTextEdit(true);
                                          } else {
                                            setActivityEditUrl(activities.text);
                                            setActivityUrlEdit(true);
                                          }
                                          setActivityPopup(true);
                                          setActivityTitleEdit(
                                            activities.title
                                          );
                                          setActivityID(
                                            activityDetails[0].activity_id
                                          );
                                          setContentId(activities.content_id);
                                        }}
                                      >
                                        <FaEdit />
                                      </span>
                                      <span
                                        onClick={() => {
                                          setActivityID(
                                            activityDetails[0].activity_id
                                          );
                                          setContentId(activities.content_id);
                                        }}
                                      >
                                        <RiDeleteBin5Line />
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        setActivityID(
                                          activityDetails[0].activity_id
                                        );
                                        setContentId(activities.content_id);
                                      }}
                                    >
                                      <RiDeleteBin5Line />
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className={style.ActivityContent}>
                            {activities.type === "text" ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: activities.text,
                                }}
                              ></p>
                            ) : activities.type === "url" ? (
                              <a
                                href={activities.text}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {activities.text}
                              </a>
                            ) : activities.type === "video" ? (
                              <video controls width="100%" height="auto">
                                <source
                                  src={activities.text}
                                  type="video/mp4"
                                />
                              </video>
                            ) : activities.type === "image" ? (
                              <div className={style.imageView}>
                                <img src={activities.text} alt="Activity" />
                              </div>
                            ) : null}
                          </div>
                          <div ref={commentDivRef} className={style.CommentDiv}>
                            <div className={style.CommentHead}>
                              <p>Comments</p>
                            </div>

                            <div>
                              <div className={style.sectionValues}>
                                <label
                                  className={style.labelCmnt}
                                  htmlFor="fileInput"
                                >
                                  <IoDocumentAttach size={20} />{" "}
                                  <span style={{ marginLeft: "8px" }}>
                                    Choose your file to attach
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }} // Hide the input field
                                />
                              </div>
                            </div>

                            <div className={style.commentbox}>
                              <div className={style.CommentContent}>
                                <div
                                  onClick={() =>
                                    setCommentId(activities.content_id)
                                  }
                                  className={style.CommentEditor}
                                >
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={
                                      activityComments[activities.content_id] ||
                                      ""
                                    } // Initialize with existing data or empty string
                                    onChange={(event, editor) =>
                                      commentEditorChange(
                                        event,
                                        editor,
                                        activities.content_id
                                      )
                                    }
                                    onReady={(editor) => {
                                      editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                          {
                                            height: "240px",
                                            width: "500px",
                                          },
                                          editor.editing.view.document.getRoot()
                                        );
                                      });

                                      // Prepopulate state to avoid requiring user interaction
                                      if (
                                        !activityComments[activities.content_id]
                                      ) {
                                        setActivityComments((prevComments) => ({
                                          ...prevComments,
                                          [activities.content_id]: "", // Initialize to empty string
                                        }));
                                      }
                                    }}
                                    config={{
                                      toolbar: [
                                        "",
                                        "|",
                                        "bold",
                                        "italic",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "blockQuote",
                                      ],
                                      placeholder: "Write a comment",
                                    }}
                                  />
                                </div>
                                <div className={style.CommentButton}>
                                  <button
                                    onClick={() => {
                                      commentAdd(
                                        activityDetails[0]?.activity_id || "",
                                        activities.content_id,
                                        activityComments[
                                          activities.content_id
                                        ] || " " // Provide a fallback value
                                      );
                                    }}
                                  >
                                    Post
                                  </button>
                                </div>
                              </div>
                              <div
                                className={`${style.CommentDisplay} ${
                                  activities.comments?.length === 0
                                    ? style.noComments
                                    : ""
                                }`}
                              >
                                {activities.comments?.length > 0 ? (
                                  activities.comments.map(
                                    (comment, commentIndex) => {
                                      const commentTime = new Date(
                                        comment.time
                                      );
                                      const formattedDateTime = commentTime
                                        .toISOString()
                                        .slice(0, 19)
                                        .replace("T", " ");
                                      const parseAndModifyLinks = (html) => {
                                        const parser = new DOMParser();
                                        const doc = parser.parseFromString(
                                          html,
                                          "text/html"
                                        );
                                        doc
                                          .querySelectorAll("a")
                                          .forEach((a) =>
                                            a.setAttribute("target", "_blank")
                                          );
                                        return doc.body.innerHTML;
                                      };

                                      return (
                                        <div
                                          key={commentIndex}
                                          className={style.CommentList}
                                        >
                                          <div className={style.profileIcon}>
                                            <span>
                                              {comment.uploaded_by.name.charAt(
                                                0
                                              )}
                                            </span>
                                          </div>

                                          <div className={style.CommentText}>
                                            <p>
                                              <strong>
                                                {comment.uploaded_by.name}
                                              </strong>{" "}
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {formattedDateTime}
                                              </span>
                                            </p>
                                            <p
                                              className={style.textP}
                                              dangerouslySetInnerHTML={{
                                                __html: parseAndModifyLinks(
                                                  comment.content
                                                ),
                                              }}
                                            ></p>

                                            {comment.file_url &&
                                              comment.file_type === "img" && (
                                                <div
                                                  className={style.imgBox}
                                                  onClick={() =>
                                                    openOverlay(
                                                      comment.file_url,
                                                      "img"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={comment.file_url}
                                                    alt="Image"
                                                  />
                                                </div>
                                              )}
                                            {comment.file_url &&
                                              comment.file_type === "pdf" && (
                                                <div
                                                  className={style.pdfBox}
                                                  onClick={() =>
                                                    openOverlay(
                                                      comment.file_url,
                                                      "pdf"
                                                    )
                                                  }
                                                >
                                                  <iframe
                                                    src={comment.file_url}
                                                    title="PDF Viewer"
                                                    style={{
                                                      width: "100%",
                                                      height: "400px",
                                                    }}
                                                  ></iframe>
                                                </div>
                                              )}
                                            {comment.file_url &&
                                              comment.file_type === "video" && (
                                                <div
                                                  className={style.VideoBox}
                                                  onClick={() =>
                                                    openOverlay(
                                                      comment.file_url,
                                                      "video"
                                                    )
                                                  }
                                                >
                                                  <video
                                                    controls
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <source
                                                      src={comment.file_url}
                                                      type="video/mp4"
                                                    />
                                                    Your browser does not
                                                    support the video tag.
                                                  </video>
                                                </div>
                                              )}

                                            {/* Overlay */}
                                            {isOverlayOpen && (
                                              <div
                                                className={style.overlay}
                                                onClick={closeOverlay}
                                              >
                                                <div
                                                  className={
                                                    style.overlayContent
                                                  }
                                                  // onClick={(e) =>
                                                  //   e.stopPropagation()
                                                  // }
                                                >
                                                  <button
                                                    className={
                                                      style.closeButton
                                                    }
                                                    onClick={closeOverlay}
                                                  >
                                                    &times;
                                                  </button>
                                                  {overlayContent.type ===
                                                    "img" && (
                                                    <img
                                                      src={overlayContent.url}
                                                      alt="Overlay Image"
                                                    />
                                                  )}
                                                  {overlayContent.type ===
                                                    "pdf" && (
                                                    <iframe
                                                      src={overlayContent.url}
                                                      title="Overlay PDF Viewer"
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    ></iframe>
                                                  )}
                                                  {overlayContent.type ===
                                                    "video" && (
                                                    <video
                                                      controls
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    >
                                                      <source
                                                        src={overlayContent.url}
                                                        type="video/mp4"
                                                      />
                                                      Your browser does not
                                                      support the video tag.
                                                    </video>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div
                                    className={`${style.comments} ${style.noComments}`}
                                  >
                                    <p>No comments available.</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <p>No activity data available.</p>
                  )
                ) : (
                  <p>No activity data available.</p>
                )}
              </div>

              {isChatPopupVisible && (
                <ChatPopup
                  token={key}
                  setChatIcon={closeChatPopup}
                  aiID={aiID}
                  singleTopic={singleTopic}
                  mainID={main_topic_id}
                  topicNo={topicNo}
                  roomID={roomID}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className={style.chatIconWrapper}>
        <div className={style.chatBox}>
          <BsRobot
            className={style.chatIcon} // You can style this icon as needed
            onClick={openChatPopup} // Open ChatPopup when clicked
          />
        </div>
      </div>
    </div>
  );
}

export default Activity;
