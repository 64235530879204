import React, { useState, useEffect, useRef } from "react";
import { IoSend } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import Loader from "../Loder/Loader";
import style from "./ChatPopup.module.css";
import { baseurl } from "../Url";

const ChatPopup = ({
  token,
  UserName,
  setChatIcon,
  aiID,
  singleTopic,
  mapped,
  learning,
  mainID,
  topicNo,
  roomID,
}) => {
  const [messages, setMessages] = useState([]);
  const [chat, setChat] = useState("");
  const [chatLoader, setChatLoader] = useState(false);
  const [popup, setPopup] = useState(true);
  const [bot, setBot] = useState(true);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const orgID = localStorage.getItem("ORGID");
  const USERID = localStorage.getItem("USERID");

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  const chatRoom = async () => {
    setChatLoader(true);
    try {
      const response = await fetch(`${baseurl}/user/room/chat/get`, {
        method: "POST",
        body: JSON.stringify({
          org_id: orgID,
          room_id: roomID,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data.status === true && Array.isArray(data.data)) {
        setMessages(data.data);
      } else {
        console.error(
          "Chat data not available:",
          data.description || "No data provided."
        );
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
      setMessages([]);
    } finally {
      setChatLoader(false);
    }
  };

  useEffect(() => {
    if (roomID) {
      chatRoom();
    }
  }, [roomID]);

  const chatSend = async (chatText) => {
    await fetch(`${baseurl}/ai/tutor/chat`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
        topic_id: singleTopic?.id,
        room_id: roomID,
        content: chatText,
        ai_id: aiID,
        user_id: USERID,
        type: "text",
        topic_name: singleTopic?.value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          chatRoom();
          setChat("");
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && chat.trim() !== "") {
      chatSend(chat);
      setChatLoader(true);
    }
  };

  const handleChatPopupClose = () => {
    setPopup(false);
    setBot(false);
    setChatIcon(true); // Close the chat popup
  };

  return (
    <>
      {popup && (
        <div className={`${style.POPUP} ${bot ? "" : style.closing}`}>
          <div className={style.SummaryPopup}>
            {chatLoader && (
              <div className={style.Loaderchat}>
                <Loader />
              </div>
            )}
            <div className={style.popupHead}>
              <div className={style.HeadText}>
                <p>DUNO AI Tutor</p>
              </div>
              <AiOutlineClose
                className={style.PopupIcon}
                onClick={handleChatPopupClose}
              />
            </div>
            <div className={style.PopupContent} ref={containerRef}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`${style.message} ${
                    message.sender === "me" ? style.sender : style.receiver
                  }`}
                >
                  {message.content}
                </div>
              ))}
            </div>
          <div className={style.bottomPart}>
              <div className={style.TopicButtons}>
                <button
                  onClick={() => chatSend("summarize")}
                  disabled={chatLoader}
                >
                  Summarize to me
                </button>
                <button onClick={() => chatSend("explain")} disabled={chatLoader}>
                  Explain to me
                </button>
              </div>
              <div className={style.BotInput}>
                <div className={style.Input}>
                  <input
                    ref={inputRef}
                    value={chat}
                    onChange={(e) => setChat(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="Type a message..."
                  />
                </div>
                <IoSend
                  onClick={() => {
                    if (chat.trim()) {
                      chatSend(chat);
                      setChatLoader(true);
                    }
                  }}
                  className={style.Icon}
                  disabled={!chat.trim()}
                />
              </div>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPopup;
