import React, { useState, useEffect,useRef } from "react";
import style from "./Assessment.module.css";
import { useLocation,useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TrophyImage from "../../assests/Trophy.png";
import SecondTrophyImage from "../../assests/SecondTrophy.png";
import ThirdTrophyImage from "../../assests/ThirdTrophy.png";
import FourthTrophyImage from "../../assests/FourthTrophy.png";
import { Doughnut } from "react-chartjs-2";
import Loader from "../Loder/Loader";
import lessonImage from "../../assests/online lesson.png"
import timeImage from "../../assests/Time.png"
import questionImage from  "../../assests/19 Questionnaire.png"
import motivationImage from "../../assests/Motivation.png"
import {
  Alert,
  Snackbar,

} from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useSelector } from 'react-redux';
import FailedImage from "../../assests/TrainImage.png"
import { AiOutlineClose } from "react-icons/ai";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaAnglesLeft,FaAnglesRight } from "react-icons/fa6";
import OutlinedInput from '@mui/material/OutlinedInput';
import Coins from "../../assests/Animations/CoinsGif.gif"
import Lottie from "lottie-react";
import coinsLottie from "../../Animation/Animation -Coins.json"
import { fetchUserDetails } from '../../Slices/UserSlice';
import { useDispatch } from "react-redux";

const Assessment = () => {

  const navigate =useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainID;
  const topicNo = state?.topicNo;
  const aiID = state?.aiID;

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const mainTopicIds = localStorage.getItem("main_topic_id");


  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [finishPopup,setFinishPopup]=useState(false)
  const [result, setResult] = useState(false);
  const [summaryDetail,setSummaryDetail]=useState({})
  const inputRefs = useRef({});
  const [loading, setLoading] = useState(true);
  const [trialUser,setTrailUser]=useState(false)
  const [error,setError]=useState("")
  const [failedPopup,setFailedPopup]=useState(false)
  const highlightRefs = useRef({});
  const quillRef = useRef();
  const [achievementPopup,setAchievementPopup]= useState(false);
  const [collectPopup,setCollectPopup]= useState(false);
  const [carouselData,setCarouselData]=useState([])
  const [points,setPoints]=useState("")
  const [wrongAnswerCount, setWrongAnswerCount] = useState(0);



  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentSlideIndex(
      (prevIndex) => (prevIndex - 1 + carouselData.length) % carouselData.length
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     nextSlide();
  //   }, 3000); 
  //   return () => clearInterval(interval); 
  // }, []);

  

  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );


  const totalCount = assessment.length;
  const correctCount = Object.values(assessmentData).filter(
    (data) => data.status === "correct"
  ).length;
  const incorrectCount = Object.values(assessmentData).filter(
    (data) => data.status === "incorrect"
  ).length;
  const skippedCount = Object.values(assessmentData).filter(
    (data) => data.status === "skipped"
  ).length;

  const handleOptionClick = (option, index) => {
    const uniqueId = currentAssessment.unique_id;
    const isMultiSelect = currentAssessment.isMultiSelect;

    setAssessmentData((prev) => {
        const prevAnswers = prev[uniqueId]?.answers || [];
        let newAnswers;

        if (isMultiSelect) {
            if (prevAnswers.includes(option)) {
                newAnswers = prevAnswers.filter((ans) => ans !== option);
            } else {
                newAnswers = [...prevAnswers, option];
            }
        } else {
            newAnswers = [option];
        }

        return {
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: newAnswers,
            },
        };
    });

    setSelectedOptions((prev) => {
        if (isMultiSelect) {
            const selectedIndexes = prev[uniqueId] || [];
            return {
                ...prev,
                [uniqueId]: selectedIndexes.includes(index)
                    ? selectedIndexes.filter((i) => i !== index)
                    : [...selectedIndexes, index],
            };
        } else {
            return {
                ...prev,
                [uniqueId]: [index],  
            };
        }
    });
};


  const handleInputChange = (index, value) => {
    const uniqueId = currentAssessment.unique_id;
    setAssessmentData((prev) => {
      const updatedAnswers = [...(prev[uniqueId]?.answers || [])];
      updatedAnswers[index] = value;
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: updatedAnswers,
        },
      };
    });
  };
  
  const formatQuestionText = (text) => {
    const formattedText = text.replace(/<\/?p>/g, '');
    
    return (
      <span
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    );
  };
  
  const renderQuestionWithInputs = (question) => {
    const parts = question.split(/\[blank (\d+)\]/);
    const uniqueId = currentAssessment.unique_id;
    const status = assessmentData[uniqueId]?.status;
    
    return (
      <p> {/* Flexbox container */}
        {parts.map((part, index) => {
          if (index % 2 === 1) {
            const blankIndex = parseInt(parts[index], 10) - 1;
            let inputStyle = {};
  
            if (status === "correct") {
              inputStyle = { color: "#00DFC7" };
            } else if (status === "incorrect") {
              inputStyle = { color: "#FF2443" };
            } else {
              inputStyle = { color: "#FF7A00" };
            }
  
            // Calculate dynamic width based on input value length
            const inputValue = assessmentData[uniqueId]?.answers[blankIndex] || "";
            const calculatedWidth = inputValue.length * 10 + 20;
            const inputWidth = `${Math.min(Math.max(calculatedWidth, 100), 500)}px`;
  
            return (
              <React.Fragment key={index}>
                <input
                  spellCheck="false"
                  type="text"
                  value={inputValue}
                  onChange={(e) => handleInputChange(blankIndex, e.target.value)}
                  className={style.blankInput}
                  disabled={assessmentData[currentAssessment.unique_id]?.answered}
                  style={{ ...inputStyle, width: inputWidth }}
                />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                {formatQuestionText(part)}
              </React.Fragment>
            );
          }
        })}
      </p>
    );
  };
  
  
  const renderQuestionWithDropdowns = (question, options) => {
    const uniqueId = currentAssessment.unique_id;
    const parts = question.split(/(\[dropdown-\d+\])/);
  
    return (
      <p >
        {parts.map((part, index) => {
          const dropdownMatch = part.match(/\[dropdown-(\d+)\]/);
  
          if (dropdownMatch) {
            const dropdownId = parseInt(dropdownMatch[1], 10); 
            const dropdownOptions = options.find(option => option.id === dropdownId)?.options || [];
  
            return (
              <React.Fragment key={index}>
                <select
                  value={assessmentData[uniqueId]?.answers?.[dropdownId] || ""}
                  onChange={(e) => {
                    const newAnswers = {...(assessmentData[uniqueId]?.answers || {})};
                    newAnswers[dropdownId] = e.target.value;
                    setAssessmentData((prev) => ({
                      ...prev,
                      [uniqueId]: {
                        ...prev[uniqueId],
                        answers: newAnswers,
                      },
                    }));
                  }}
                  className={style.blankDropdown}
                  disabled={assessmentData[currentAssessment.unique_id]?.answered}
                >
                  <option disabled value="">
                    Select
                  </option>
                  {dropdownOptions.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            );
          }
          return <React.Fragment key={index}>{formatQuestionText(part)}</React.Fragment>;
        })}
      </p>
    );
  };
  
  
  
  

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const uniqueId = currentAssessment.unique_id;
  
    setAssessmentData((prev) => {
      const prevAnswers = prev[uniqueId]?.answers || { left: [], right: [] };
  
      let newAnswers = { ...prevAnswers };
  
      if (sourceDroppableId.startsWith("left") && destinationDroppableId.startsWith("left")) {
        const items = Array.from(newAnswers.left);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.left = items;
      } else if (sourceDroppableId.startsWith("right") && destinationDroppableId.startsWith("right")) {
        const items = Array.from(newAnswers.right);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.right = items;
      }
  
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: newAnswers,
        },
      };
    });
  };



  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const uniqueId = currentAssessment.unique_id;
    const items = Array.from(assessmentData[uniqueId]?.answers || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessmentData((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: items,
      },
    }));
  };

  const handleCellValueChange = (rowIndex, colIndex, value, clueID, clueNumber) => {
    const uniqueId = currentAssessment.unique_id;

    setAssessmentData(prevData => {
        const newAnswers = prevData[uniqueId]?.answers.map((row, i) =>
            row.map((cell, j) =>
                i === rowIndex && j === colIndex
                    ? { ...cell, cluevalue: value.toUpperCase(), clueID, clueNumber }
                    : cell
            )
        ) || [];

        return {
            ...prevData,
            [uniqueId]: {
                ...prevData[uniqueId],
                answers: newAnswers,
            },
        };
    });
    const rows = assessmentData[uniqueId]?.answers.length || 0;
    const cols = assessmentData[uniqueId]?.answers[0]?.length || 0;

    let nextRowIndex = rowIndex+ 1;
    let nextColIndex = colIndex ; 

    if (nextRowIndex >= rows) {
        nextColIndex += 1; 
        nextRowIndex = 0; 
    }
    if (nextRowIndex < rows && inputRefs.current[uniqueId]?.[nextRowIndex * cols + nextColIndex]) {
        const nextInputRef = inputRefs.current[uniqueId][nextRowIndex * cols + nextColIndex];
        if (nextInputRef) {
            console.log("Focusing on:", nextInputRef);
            nextInputRef.focus();
        }
    }
};

const getBackgroundColor = (status) => {
  switch (status) {
    case "correct":
      return "#00DFC7";
    case "incorrect":
      return "#FF7C8E";
    default:
      return "transparent";
  }
};

const getHighlightColor = (status) => {
  switch (status) {
    case 'correct':
      return '#00FFC1'; 
    case 'incorrect':
      return '#FF7C8E';
    default:
      return '#FF7A00'; 
  }
};



  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
  };

  const handleSkip = () => {
    const uniqueId = currentAssessment?.unique_id;
    if (assessmentData[uniqueId]?.status === 'viewed') {
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          status: 'skipped',
        },
      }));
    }

    
    if (uniqueId && assessmentData[uniqueId]) {
        const currentAssmnt = assessment[currentIndex];
        let resetAnswers;
        let resetHighlightContent = "";
        let resetLastSelectedText = "";
        let resetAcrossClues = [];
        let resetDownClues = [];

        if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
        );
        const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
        );
            resetAnswers = {
                left: filteredLeft,
                right: filteredRight,
            };
        } else if (currentAssmnt.type === "Rearrange") {
            resetAnswers = currentAssmnt.options || [];
        } else if (currentAssmnt.type === 'cross word puzzle') {
            resetAnswers = currentAssmnt.options.map(row => (
                row.map(cell => ({
                    clueID: cell.clueID || "",
                    clueNumber: cell.clueNumber,
                    cluevalue: '',
                    disabled: cell.cluevalue === '',
                }))
            ));

            resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: ''
            }));
            resetDownClues = currentAssmnt.right.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: '' 
            }));
        } else if (currentAssmnt.type === "highlight") {
            resetHighlightContent =currentAssmnt.options.replace(
              /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
              '$1'
          ) || ""; 
          resetHighlightContent =resetHighlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1')|| ""; 

            resetLastSelectedText = "";
        } else {
            resetAnswers = [];
        }

        setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: resetAnswers,
                highlightContent: resetHighlightContent,
                lastSelectedText: resetLastSelectedText,
                cluesAcross: resetAcrossClues,
                cluesDown: resetDownClues,
            },
        }));
        setSelectedOptions((prev) => {
          const { [uniqueId]: _, ...rest } = prev; 
          return rest;
      });
    }

    // Proceed to next question
    setCurrentIndex((prevIndex) => ( prevIndex + 1));
    setValidateClicked(false);
};


async function assessmentGet() {
  await fetch(`${baseurl}/ai/assignment/generation/for/user/new`, {
    method: "POST",
    body: JSON.stringify({
      Organization_ID: OrgID,
      topic_id: Topic.id,
      topic_name: Topic.value,
      learning_id: learning_id,
      main_topic_id: mainTopicIds,
      user_id: USERID ,
      admin_mapped: "true",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.status === true) {
        const modifiedAssessments = data.data[0]?.assessments.map((assessment, index) => {
          const baseQuestion = {
            ...assessment,
            question_no: index + 1,
          };
          if (assessment.type === "Match the following" || assessment.type === "Match the following with image") {
            return {
              ...baseQuestion,
              draggable: "right",
            };
          } else if (assessment.type === "multiple choices") {
            const isMultiSelect = assessment.answer.length > 1;
            return {
              ...baseQuestion,
              isMultiSelect,
            };
          }else if (assessment.type === "fill in the blanks"||assessment.type === "select the answers") {
            return {
              ...baseQuestion,
              question: assessment.question.replace(/<\/p>/g, '</p><br/>'),
            };
          }
          return baseQuestion;
        });

        setAssessment(modifiedAssessments);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setAssessment([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
}


  async function assessmentAnswer() {
    const questionId = currentAssessment.id
      ? currentAssessment.id.toString()
      : "";
      let answerToSend;
      if (currentAssessment.type === "Match the following"||currentAssessment.type === "Match the following with image") {
          if (currentAssessment.draggable === "right") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.right || [];
          } else if (currentAssessment.draggable === "left") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.left || [];
          }
      }else if(currentAssessment.type === "cross word puzzle"){
         answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answerGroup => (
          Array.isArray(answerGroup) ? 
            answerGroup.map(answer => ({
                clueID: answer.clueID,
                clueNumber: answer.clueNumber,
                cluevalue: answer.cluevalue,
            })) :
            answerGroup 
      ));
      }else if(currentAssessment.type === "highlight"){
        let selectedText = assessmentData[currentAssessment.unique_id]?.lastSelectedText || "";
        if (selectedText.endsWith(".")) {
            selectedText = selectedText.slice(0, -1);
        }
        answerToSend = [selectedText] || [];
    }else if (currentAssessment.type === "fill in the blanks") {
        answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answer => answer.trim()) || [];
      }else if (currentAssessment.type === "select the answers") {
        const answersObj = assessmentData[currentAssessment.unique_id]?.answers || {};
        answerToSend = Object.values(answersObj).length > 0 ? Object.values(answersObj) : [];
      } else {
          answerToSend = assessmentData[currentAssessment.unique_id]?.answers || [];
      }
  

    await fetch(`${baseurl}/user/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        question: currentAssessment.question,
        question_id: questionId,
        answer: answerToSend,
        ai_id: aiID,
        main_topic_id: main_topic_id,
        user_id: USERID,
        question_type: currentAssessment.type,
        options: currentAssessment.type === "highlight" ? [] : currentAssessment.options || [],
        unique_id: currentAssessment.unique_id,
        generated_by: currentAssessment.generation === "admin" ? "admin" : "",
        left: currentAssessment.left ? currentAssessment.left : [],
        right: currentAssessment.right ? currentAssessment.right : [],
        actual_ans:currentAssessment.type === "cross word puzzle"?currentAssessment.options:currentAssessment.answer,
        question_explained:currentAssessment.question_explained,
        time_taken: "",
        markif_correct: currentAssessment.mark,
        markif_incorrect: currentAssessment.negative_mark,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const isCorrect = data.data[0].answer_is === "Correct";
        const newStatus = isCorrect ? "correct" : "incorrect";
        let sanitizedExplanation = data.data[0]?.question_explained;
        if (sanitizedExplanation === "<p><br></p>") {
            sanitizedExplanation = ""; 
        }
        setWrongAnswerCount((prev) => {
          const newCount = isCorrect ? 0 : prev + 1;
          if (newCount >= 3) setFailedPopup(true);
          return newCount;
        });
        const matchFieldExists = data.data[0]?.match !== undefined;
        setAssessmentData((prev) => ({
          ...prev,
          [currentAssessment.unique_id]: {
            ...prev[currentAssessment.unique_id],
            status: newStatus,
            explanations: sanitizedExplanation,
            answered:true,
            ...(matchFieldExists && { match: data.data[0].match }),
            actual_ans:data.data[0]?.actual_ans,
            left:data.data[0]?.left,
            right:data.data[0]?.right,
            question_type:data.data[0]?.question_type
          },
        }));
        setValidateClicked(true);
      });
  }

  async function endAssessment() {
    await fetch(`${baseurl}/assement/end-batch-test`, {
      method: "POST",
      body: JSON.stringify({
        learning_id: learning_id,
        topic_id: Topic.id,
        user_id: USERID,
        main_topic_id: main_topic_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
       if(data.status===true){
        setResult(true)
        setSummaryDetail(data.data)
        setCarouselData(data.data?.awards)
        const coinsArray = data.data?.coins || [];
        const coinsSum = coinsArray.reduce((sum, coin) => sum + Number(coin), 0); // Ensure coins are converted to numbers
        setPoints(coinsSum);
        setFinishPopup(false)
        setTimeout(() => {
          // Check if awards array exists and is not empty
          if (data.data?.awards && data.data.awards.length > 0) {
              setAchievementPopup(true);
          }
      }, 1000);
       }
      });
  }


  const explanationWithDefaultImageSize = assessmentData[currentAssessment.unique_id]?.explanations.replace(
    /<img(.*?)>/g,
    '<img$1 width="200" height="auto">'
  );
  

  useEffect(() => {
    if (USERID) {
      assessmentGet();
    }
  }, [USERID]); 

  const handleSelectionChange = (range, oldRange, source) => {
    const quill = quillRef.current.getEditor();
    const uniqueId = currentAssessment.unique_id; 

    if (range && range.length > 0) {
        const selectedText = quill.getText(range.index, range.length).trim();
        const currentData = assessmentData[uniqueId];
        const status = currentData.status;
        const previousRange = currentData.previousRange;
        if (
          previousRange &&
          previousRange.index === range.index &&
          previousRange.length === range.length
        ) {
          quill.formatText(range.index, range.length, 'color', false);
          setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...currentData,
                previousRange: null, 
                lastSelectedText: '', 
                highlightContent: quill.root.innerHTML, 
            },
        }));
        } else {
            if (previousRange) {
                quill.formatText(previousRange.index, previousRange.length, 'color', false);
            }
            const highlightColor = getHighlightColor(status);
            quill.formatText(range.index, range.length, 'color', highlightColor);
            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    ...currentData,
                    previousRange: range, 
                    lastSelectedText: selectedText,
                    highlightContent: quill.root.innerHTML,
                },
            }));
        }
    }
};


const handleQuillChange = (value) => {
  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    const container = quill.root.parentElement;
    container.style.border = 'none';  
    container.style.boxShadow = 'none';  
}
  const uniqueId = assessment[currentIndex]?.unique_id;
  setAssessmentData(prev => ({
      ...prev,
      [uniqueId]: {
          ...prev[uniqueId],
          highlightContent: value, 
      },
  }));
};

useEffect(() => {
  if(quillRef.current){
  const quill = quillRef.current.getEditor();
  const uniqueId = currentAssessment.unique_id;
  const status = assessmentData[uniqueId]?.status;
  const previousRange = assessmentData[uniqueId]?.previousRange;
  if (quill && status && previousRange) {
      const highlightColor = getHighlightColor(status);
      quill.formatText(previousRange.index, previousRange.length, 'color', highlightColor);
  }
}
}, [assessmentData[currentAssessment.unique_id]?.status]);





  useEffect(() => {
    if (assessment.length > 0) {
        const currentAssmnt = assessment[currentIndex];
        const uniqueId = currentAssmnt?.unique_id;

        setCurrentAssessment(currentAssmnt);

        if (!assessmentData[uniqueId]) {
            let initialAnswers;
            let initialAcrossClues = [];
            let initialDownClues = [];
            let highlightContent = ""
            let lastSelectedText = ""

            if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
                const filteredLeft = (currentAssmnt.left || []).filter(
                    (leftData) => leftData.trim() !== ""
                );
                const filteredRight = (currentAssmnt.right || []).filter(
                    (rightData) => rightData.trim() !== ""
                );
                initialAnswers = {
                    left: filteredLeft,
                    right: filteredRight,
                };
            } else if (currentAssmnt.type === "Rearrange") {
                initialAnswers = currentAssmnt.options || [];
            } else if (currentAssmnt.type === 'cross word puzzle') {
                const grid = currentAssmnt.options.map(row => (
                    row.map(cell => ({
                        clueID: cell.clueID || "",
                        clueNumber: cell.clueNumber,
                        cluevalue: '',
                        disabled: cell.cluevalue === '',
                    }))
                ));
                initialAnswers = grid;

                initialAcrossClues = currentAssmnt.left.map(clueObj => ({
                    id: clueObj.id,
                    number: clueObj.number,
                    clue: clueObj.clue,
                    answer: ''
                }));
                initialDownClues = currentAssmnt.right.map(clueObj => ({
                    id: clueObj.id,
                    number: clueObj.number,
                    clue: clueObj.clue,
                    answer: ''
                }));
            } else if (currentAssmnt.type === "highlight") {
              // Remove color style from any tag containing `color: rgb(255, 122, 0);`
              highlightContent = currentAssmnt.options.replace(
                  /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
                  '$1'
              );

              // Remove any remaining <span> tags, if needed
              highlightContent = highlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');

              lastSelectedText = "";
          } else {
                initialAnswers = [];
            }

            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    answers: initialAnswers,
                    status: "",
                    explanations: "",
                    cluesAcross: initialAcrossClues, 
                    cluesDown: initialDownClues, 
                    highlightContent:highlightContent,
                    lastSelectedText:lastSelectedText,
                    originalContent: highlightContent,
                    previousRange: null
                },
            }));
        } else if (!assessmentData[uniqueId]?.status) {
            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    ...prev[uniqueId],
                    status: 'viewed',
                },
            }));
        }
    }
}, [currentIndex, assessment, assessmentData]);

useEffect(() => {
  if (currentAssessment) {
      if (!inputRefs.current[currentAssessment.unique_id]) {
          inputRefs.current[currentAssessment.unique_id] = [];
      }
  }
}, [currentAssessment]);

useEffect(()=>{
const userType=userStatus === 'succeeded' && userDetails &&userDetails.user_type
console.log(userType);

if(userType==="Trail"){
  setTrailUser(true)
}
else{
  setTrailUser(false)
}
},[])
  

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setError(false);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          Please enter answer..
        </Alert>
      </Snackbar>
      <div className={style.assessmentSection}>
        <div className={style.header} style={{background:trialUser?"#FF8800":""}}>
          <div className={style.headerText}>
            {trialUser?<h5>Trial Test</h5>:
           <>
              <span>Topic {topicNo}</span>
              <h4>{Topic.value.replace(/^Topic \d+:\s*/, "")}</h4>
           </>
            }
          </div>
        </div>
        {loading?<Loader/>:
        <>
        {currentAssessment.id ? (
          <div className={style.body}>

           <div className={style.bodyTop}>
              <div style={{border:result?"none":""}} className={style.bodyLeft}>
              {result ? (
                  <>
                    <div className={style.resultSection}>
                          {
                            summaryDetail?.percentage!== undefined && (
                              <>
                                {summaryDetail.percentage >= 85 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#D09B00"}}>Congratulations! </h4>
                                      <p>You’ve done an excellent job in the test. Keep up the great work.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={TrophyImage} alt="Winner" />
                                    </div>
                                  </>
                                ) : summaryDetail.percentage >= 60 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#00CBF4"}}>Well Done! </h4>
                                      <p>You’ve performed well in the test. Keep up the good work.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={SecondTrophyImage} alt="Winner" />
                                    </div>
                                  </>
                                ) : summaryDetail.percentage >= 40 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#FF8800"}}>Nice effort!</h4>
                                      <p>There’s room for improvement, but you’re on the right track. Keep practicing.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={ThirdTrophyImage} alt="Average" />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#FF2443"}}>Don't give up!</h4>
                                      <p>With consistent practice, you can achieve your goals.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={FourthTrophyImage} alt="Try Again" />
                                    </div>
                                  </>
                                )}
                                <div className={style.resultSectionBottomContent}>
                                  <p>You scored</p>
                                  <h4>{summaryDetail.correct_answers}</h4>
                                </div>
                              </>
                            )
                          }
                        </div>
                        {!trialUser?
                        <div className={style.resultBottom}>
                          <p>The journey to mastering grammar continues! </p>
                          <h4>Let's move on to the next topic and keep improving.</h4>
                          <button onClick={(e) => {
                  e.preventDefault();
                 navigate("/new/learnings")
                }}>Choose my next topic &nbsp;{">>"}</button>
                        </div>
                        :null}
                  </>
                ) : (
                <>
                  <div className={style.questionSection}>
                    <div className={style.question}>
                      <div className={style.questionLeft}><span>{currentAssessment.question_no}.</span></div>
                      <div className={style.questionRight}>
                        <div className={style.questionContainer}>
                          {currentAssessment.type === "fill in the blanks" ? (
                            <div className={style.inputWrapper}>
                              {renderQuestionWithInputs( currentAssessment.question )}
                            </div>
                          ) : currentAssessment.type === "select the answers" ? (
                            <div className={style.dropDownWrapper}>
                              {renderQuestionWithDropdowns(
                                currentAssessment.question,
                                currentAssessment.options
                              )}
                            </div>
                          ): (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: currentAssessment.question,
                              }}
                            ></p>
                          )}
                        </div>
                        <div className={style.questionBody}>
                      {currentAssessment.type === "ordinary" ? (
                        <div className={style.ordinaryContainer}>
                          <div className={style.ordinarySection}>
                            <div className={style.ordinarySectionRight}>
                            <input
                            spellCheck="false"
                                disabled={assessmentData[currentAssessment.unique_id]?.answered}
                                style={{
                                  backgroundColor: assessmentData[currentAssessment.unique_id]?.status === "correct"
                                    ? "#00DFC7"
                                    : assessmentData[currentAssessment.unique_id]?.status === "incorrect"
                                    ? "#FF7C8E"
                                    : "transparent"
                                }}
                                onChange={(e) => {
                                  const uniqueId = currentAssessment.unique_id;
                                  setAssessmentData((prev) => ({
                                    ...prev,
                                    [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: [e.target.value],
                                    },
                                  }));
                                }}
                                value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                              />
                            </div>
                          </div>
                        </div>
                      ) : currentAssessment.type === "multiple choices" ? (
                        <div className={style.multipleChoiceContainer}>
                          {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                             const selectedIndexes = selectedOptions[currentAssessment.unique_id] || [];
                             const isSelected = selectedIndexes.includes(index);
                             const status = assessmentData[currentAssessment.unique_id]?.status;
                 
                             let optionClass = "";
                             if (isSelected) {
                                 optionClass =
                                     status === "correct"
                                         ? style.correct
                                         : status === "incorrect"
                                         ? style.incorrect
                                         : style.selected;
                             } else {
                                 optionClass = style.unselected;
                             }
                 
                            return (
                              <div
                                key={index}
                                onClick={() =>{
                                  if(assessmentData[currentAssessment.unique_id]?.answered!==true){
                                   handleOptionClick(option, index)}}
                                  }
                                className={`${style.multipleChoiceAnswer} ${optionClass}`}
                              >
                                <p>{option}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : currentAssessment.type === "Match the following" ? (
                        <div className={style.matchFollowingContainer}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                             const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                             let cardClassName;
                             if (matchValue === "1") {
                               cardClassName = style.correctCard;
                             } else if (matchValue === "0") {
                               cardClassName = style.errorCard;
                             } else {
                               cardClassName = style.defaultCard;
                             }
                             return (
                            <React.Fragment key={i}>
                              <Droppable droppableId={`left-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingLeft}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`left-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "right" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{leftData}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                    
                              <Droppable droppableId={`right-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingRight}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`right-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "left" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </React.Fragment>
                          )})}
                        </DragDropContext>
                      </div>
                     
                      ) : currentAssessment.type === "Match the following with image" ? (
                        <div className={style.matchFollowingContainer}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                             const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                             let cardClassName;
                             if (matchValue === "1") {
                               cardClassName = style.correctCard;
                             } else if (matchValue === "0") {
                               cardClassName = style.errorCard;
                             } else {
                               cardClassName = style.defaultCard;
                             }
                             return (
                            <React.Fragment key={i}>
                              <Droppable droppableId={`left-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingLeft}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`left-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "right" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingImageCard} ${cardClassName}`}
                                        >
                                          <img src={leftData} alt={`Option ${i + 1}`}  />
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                    
                              <Droppable droppableId={`right-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingRight}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`right-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "left" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </React.Fragment>
                          )})}
                        </DragDropContext>
                      </div>
                      ) : currentAssessment.type === "Rearrange" ? (
                        <DragDropContext onDragEnd={handleArrangeDragEnd}>
                          <Droppable droppableId="rearrange" direction="horizontal">
                            {(provided) => (
                              <div
                                className={style.reArrangeContainer}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {assessmentData[currentAssessment.unique_id]?.answers.map((option, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`option-${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.reArrangeCard}
                                      >
                                        <p>{option}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          <div className={style.statusMark}>
                            {assessmentData[currentAssessment.unique_id]?.answered && (
                              assessmentData[currentAssessment.unique_id]?.status === "correct" ? (
                                <FaCheck style={{ color: "#00DFC7"}} />
                              ) : (
                                <IoMdClose style={{ color: "#FF7C8E" }} />
                              )
                            )}
                          </div>
                        </DragDropContext>
                      ) : currentAssessment.type === "cross word puzzle"? (
                        <div className={style.crossWordContainer}>
                        <div className={style.crossWordPuzzle}>
                        {assessmentData[currentAssessment.unique_id]?.answers.map((row, rowIndex) => (
                          <div key={rowIndex} className={style.row}>
                            {row.map((cell, colIndex) => (
                              <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                                {!cell.disabled && (
                                  <input
                                    type="text"
                                    maxLength="1"
                                    className={ style.activeInput}
                                    value={cell.value}
                                    onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value, cell.clueID, cell.clueNumber)}
                                    disabled={cell.disabled}
                                    ref={el => {
                                      if (el) {
                                          if (!inputRefs.current[currentAssessment.unique_id]) {
                                              inputRefs.current[currentAssessment.unique_id] = [];
                                          }
                                          inputRefs.current[currentAssessment.unique_id][rowIndex * (assessmentData[currentAssessment.unique_id]?.answers[0]?.length || 0) + colIndex] = el;
                                      }
                                  }}
                                  />
                                )}
                                {cell.clueNumber && (  
                                  <div className={style.clueNumber}>{cell.clueNumber}</div>
                                )}
                              </div>
                            ))}
                          </div>
                          ))}
                      </div>
                      <div className={style.PuzzleQuestion}>
                        <div className={style.QuestionAcross}>
                        <h4>Across</h4>
                        {assessmentData[currentAssessment.unique_id]?.cluesAcross.map((clueObj, index) => (
                          <div key={index} className={style.QuestionAcrossInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                          </div>
                        ))}
                        
                        </div>
                        <div className={style.QuestionDown}>
                        <h4>Down</h4>
                        {assessmentData[currentAssessment.unique_id]?.cluesDown.map((clueObj, index) => (
                            <div key={index} className={style.QuestionDownInputContainer}>
                              <span>{clueObj.number}.{clueObj.clue} </span>
                              
                            </div>
                          ))}
                          
                          </div>
                      </div>
                        </div>
                      ):currentAssessment.type === "Rewrite" ? (
                        <div className={style.ordinaryContainer}>
                          <div className={style.ordinarySection}>
                            <div className={style.rewriteSectionRight}>
                            <OutlinedInput
                            
                                    spellCheck="false"
                                    disabled={assessmentData[currentAssessment.unique_id]?.answered}
                                    multiline
                                   maxRows={3}
                                   sx={{
                                    backgroundColor: getBackgroundColor(
                                      assessmentData[currentAssessment.unique_id]?.status
                                    ),
                                    borderRadius: "25px",
                                    fontFamily: "'Urbane Extra', sans-serif",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "32px",
                                    textAlign: "left",
                                    color: "#000",
                                    padding: ".5rem",
                                    outline: "none",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#898890",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#898890",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#FF7A00",
                                    },
                                    "& .MuiOutlinedInput-input	": {
                                      fontFamily: "Urbane Extra",
                                      fontSize: "16px",
                                      fontWeight: 300,
                                      lineHeight: "40px",
                                      textAlign: "left",
                                      color: "#000",
                                    },
                                  }}
                                    onChange={(e) => {
                                      const uniqueId = currentAssessment.unique_id;
                                      setAssessmentData((prev) => ({
                                        ...prev,
                                        [uniqueId]: {
                                          ...prev[uniqueId],
                                          answers: [e.target.value],
                                        },
                                      }));
                                    }}
                                    value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                                  />
                            </div>
                          </div>
                        </div>
                      ):currentAssessment.type === "highlight" ? (
                        <ReactQuill
                        ref={quillRef}
                        value={assessmentData[currentAssessment?.unique_id]?.highlightContent || ""}
                        onChange={handleQuillChange}
                        onChangeSelection={handleSelectionChange} 
                        modules={{
                            toolbar: false,
                        }}
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          border: 'none !important',
                      }}
                        readOnly={true} 
                        className={style.customQuill}
                    />

                      ):(
                        ""
                      )}
                    </div>
                    <div className={style.questionButtonSection}>
                      <div className={style.questionButtonLeft}>
                        {currentAssessment.type === "Match the following" ?<span>*Drag the boxes on the right to drop them next to the correct match on the left.</span>:""}
                        
                      </div>
                      <div  className={style.questionButtonRight}>
                     
                        <p onClick={() => {
                           if(currentIndex===assessment.length - 1){
                            setFinishPopup(true);
                           }
                           else{
                            handleSkip();
                           }
                          
                          }}
                            >Skip</p>
                          
                        {assessmentData[currentAssessment.unique_id]?.answered ? (
                          <>
                          {currentIndex!==assessment.length-1?
                            <button
                              onClick={() => handleNext()}
                              disabled={currentIndex === assessment.length - 1}
                              style={{ background: "#00CBF480" }}
                            >
                              Next
                            </button>:null}
                          </>
                        ) : (
                          <button
                          disabled={validateClicked||assessmentData[currentAssessment.unique_id]?.answered}
                        onClick={() => {
                          const uniqueId = currentAssessment.unique_id;
                          const answers = assessmentData[uniqueId]?.answers || [];

                          if (
                            currentAssessment.type === "Match the following" ||
                            currentAssessment.type === "Match the following with image"
                          ) {
                            const rightFromAssessment = currentAssessment.right.filter(right => right.trim() !== "") || [];
                            const rightFromData = answers?.right || [];

                            const areRightAnswersSame =
                              rightFromAssessment.length === rightFromData.length &&
                              rightFromAssessment.every((value, index) => value === rightFromData[index]);

                            if (areRightAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              assessmentAnswer();
                              setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "Rearrange") {
                            const options = currentAssessment.options.filter(op => op.trim() !== "") || [];
                            const areAnswersSame =
                            options.length === answers.length &&
                            options.every((value, index) => value === answers[index]);
                            if (areAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              assessmentAnswer();
                              setValidateClicked(true);
                            }
                            
                          }
                          else if(currentAssessment.type === "highlight"){
                            if(assessmentData[uniqueId]?.lastSelectedText===""){
                              setError(true);                            
                            }
                            else{
                              assessmentAnswer();
                             setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "select the answers") {
                            // Check if all dropdowns are answered
                            const allAnswered = currentAssessment.options.every((option, index) => {
                              const dropdownId = option.id; 
                              return answers[dropdownId] && answers[dropdownId].trim() !== ""; 
                            });
                      
                            if (!allAnswered) {
                              setError(true); 
                              return;
                            }
                      
                            assessmentAnswer();
                            setValidateClicked(true);
                          } 
                          else if (answers.length > 0) {
                            assessmentAnswer();
                            setValidateClicked(true);
                          } 
                          else {
                            setError(true);
                          }
                        }}
                      >
                        Submit
                      </button>

                        )}
                      </div>
                    </div>
                    {assessmentData[currentAssessment.unique_id]?.answered ? (
                    <div className={style.explanationSection}>
                      
                          <div  className={style.resultText}>
                            <p  style={{color: assessmentData[currentAssessment.unique_id]?.status === "correct" ? "#00DFC7" : "#FF2443"}}>
                            {assessmentData[currentAssessment.unique_id]?.status === "correct"
                              ? "You are correct!"
                              : "That’s ok! Try the next question."}
                            </p>
                          </div>
                          { assessmentData[currentAssessment.unique_id]?.answered&&assessmentData[currentAssessment.unique_id]?.status === "incorrect" && assessmentData[currentAssessment.unique_id]?.actual_ans.length > 0? (
                        <>
                          <div className={style.correctAnswerHeading}>
                            <p>Answer:</p>
                          </div>
                          {assessmentData[currentAssessment.unique_id]?.question_type === "Match the following" && assessmentData[currentAssessment.unique_id]?.left && assessmentData[currentAssessment.unique_id]?.left.length > 0 ? (
                                    <div className={style.answerBodyMatch}>
                                      {assessmentData[currentAssessment.unique_id]?.left.map((leftItem, index) => (
                                        <div key={index} className={style.matchRow}>
                                          <div className={style.matchRowCardLeft}>
                                            <p>{leftItem}</p>
                                          </div>
                                          <div className={style.matchRowCardRight}>
                                            <p>{assessmentData[currentAssessment.unique_id]?.actual_ans && assessmentData[currentAssessment.unique_id]?.actual_ans[index]}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : assessmentData[currentAssessment.unique_id]?.question_type === "fill in the blanks" ? (
                                    <div>
                                      {assessmentData[currentAssessment.unique_id]?.actual_ans && assessmentData[currentAssessment.unique_id]?.actual_ans.length > 0 ? (
                                        assessmentData[currentAssessment.unique_id]?.actual_ans.map((answerObj, answerIndex) => (
                                          <p key={answerIndex}>
                                           
                                           {Object.entries(answerObj).map(([key, value]) => (
                                                <span key={key}>
                                                  {value}
                                                  <br />
                                                </span>
                                              ))}
                                          </p>
                                        ))
                                      ) : (
                                        <p>No correct answers available</p>
                                      )}
                                    </div>
                                  ):assessmentData[currentAssessment.unique_id]?.question_type === "Match the following with image" && assessmentData[currentAssessment.unique_id]?.left && assessmentData[currentAssessment.unique_id]?.left.length > 0 ? (
                                      <div className={style.answerBodyMatch}>
                                        {assessmentData[currentAssessment.unique_id]?.left.map((leftItem, index) => (
                                          <div key={index} className={style.matchRow}>
                                            <div className={style.matchRowImageCard}>
                                            <img src={leftItem} alt={`Option ${index + 1}`} width="100px" />
                                            </div>
                                            <div className={style.matchRowCardRight}>
                                              <p>{assessmentData[currentAssessment.unique_id]?.right && assessmentData[currentAssessment.unique_id]?.right[index]}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    ): assessmentData[currentAssessment.unique_id]?.question_type === "select the answers" ? (
                                      <div>
                                        {assessmentData[currentAssessment.unique_id]?.actual_ans && assessmentData[currentAssessment.unique_id]?.actual_ans.length > 0 ? (
                                          assessmentData[currentAssessment.unique_id]?.actual_ans.map((answerObj, answerIndex) => (
                                            <p key={answerIndex}>
                                              {Object.values(answerObj).map((answer, index) => (
                                                <span key={index}>{answer}</span>
                                              ))}
                                            </p>
                                          ))
                                        ) : (
                                          <p>No correct answers available</p>
                                        )}
                                      </div>
                                    ) :assessmentData[currentAssessment.unique_id]?.question_type === "Rearrange" ? ( 
                                      <div>
                                        {assessmentData[currentAssessment.unique_id]?.actual_ans.length > 0 ? (
                                          <div>
                                            {assessmentData[currentAssessment.unique_id]?.actual_ans[0] && Object.entries(assessmentData[currentAssessment.unique_id]?.actual_ans[0]).map(([key, value]) => (
                                              <p key={key}>{value}</p>
                                            ))}
                                          </div>
                                        ) : (
                                          <p>No correct answers available</p>
                                        )}
                                      </div>
                                    ) :assessmentData[currentAssessment.unique_id]?.question_type === "cross word puzzle" ? ( 
                                      <div>
                                        <p style={{textDecoration:"underLine"}}>Across</p>
                                        {assessmentData[currentAssessment.unique_id]?.left.length > 0 ? (
                                          <div>
                                            {assessmentData[currentAssessment.unique_id]?.left.map(( value,i) => (
                                              <p key={i}>{value.number}.{value.answer}</p>
                                            ))}
                                          </div>
                                        ) : (
                                          <p>No correct answers available</p>
                                        )}
                                        <p style={{textDecoration:"underLine"}}>Down</p>
                                        {assessmentData[currentAssessment.unique_id]?.right.length > 0 ? (
                                          <div>
                                            {assessmentData[currentAssessment.unique_id]?.right.map(( value,i) => (
                                              <p key={i}>{value.number}.{value.answer}</p>
                                            ))}
                                          </div>
                                        ) : (
                                          <p>No correct answers available</p>
                                        )}
                                      </div>
                                    )  : (
                                      assessmentData[currentAssessment.unique_id]?.actual_ans && assessmentData[currentAssessment.unique_id]?.actual_ans.length > 0 ? (
                                      <div>
                                        {assessmentData[currentAssessment.unique_id]?.actual_ans.map((answer, answerIndex) => (
                                          <p key={answerIndex}>{answer}</p>
                                        ))}
                                      </div>
                                    ) : (
                                      <p>No answers available</p>
                                    )
                                  )}
                        </>
                      ) : (
                        ""
                      )}
                       
                      { assessmentData[currentAssessment.unique_id]?.answered && assessmentData[currentAssessment.unique_id]?.explanations?.trim()? (
                        <>
                          <div className={style.explanationHeading}>
                            <p>Explanation:</p>
                          </div>
                          <div className={style.explanationText}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: explanationWithDefaultImageSize,
                            }}
                          />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                      </div>
                    </div>
                  </div>
                </>
                )}
              </div>
              <div className={style.bodyRight}>
              {result ? (
                  <div className={style.summeryContainer}>
                    <div className={style.summeryTopContent}>
                      <div className={style.roundGraph}>
                        <Doughnut
                          data={{
                            labels: ["Completed", "Remaining"],
                            datasets: [
                              {
                                data: [
                                  summaryDetail?.percentage || 0, 
                                  100 - (summaryDetail?.percentage || 0), 
                                ],
              
                                backgroundColor: ["#03CD09", "#3E3D4212"],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          options={{
                            cutout: "85%",
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                        <div
                          className={style.graphText}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            pointerEvents: "none",
                          }}
                        >
                          {Math.round(summaryDetail?.percentage || 0)}%
  
                        </div>
                      </div>
                      <div className={style.summeryTopText}>
                        <p>Your score</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomContent}>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#00DFC7" }}>{summaryDetail?.correct_answers || 0}</span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Correct answers</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#FF2443" }}>{summaryDetail?.wrong_answers || 0} </span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Wrong answers</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#FFBE00" }}>{summaryDetail?.assessments_count-(summaryDetail?.correct_answers +summaryDetail?.wrong_answers)}</span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Skipped questions</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#595BDB" }}>{summaryDetail?.assessments_count || 0} </span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>No. of questions</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                <>
                  <div className={style.markContainer}>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Total mark</p>
                      </div>
                      <div
                        style={{ marginBottom: "8px" }}
                        className={style.markDataRight}
                      >
                        <p>
                          <span>{correctCount}</span>/{totalCount}
                        </p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Correct</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{correctCount}</p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Wrong</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{incorrectCount}</p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Not answered</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{skippedCount}</p>
                      </div>
                    </div>
                  </div>
                  <div className={style.FinishButton}>
                        <button onClick={()=>{ 
                          setFinishPopup(true)
                        }}>End Test</button>
                      </div>
                  <div className={style.questionPaletteHeader}>
                    <p>Question Palette:</p>
                  </div>
                  <div className={style.questionPalette}>
                    {numbers.map((number, index) => {
                      const questionId = assessment[index]?.unique_id;
                      const status = assessmentData[questionId]?.status;
    
                      let backgroundColor;
                      if (currentIndex === index) {
                        backgroundColor = "#D9D9D9"; 
                      } else if (status === "correct") {
                        backgroundColor = "#00DFC7";
                      } else if (status === "incorrect") {
                        backgroundColor = "#FF7C8E";
                      }else if (status === "skipped") {
                        backgroundColor = "#818085";
                      } else {
                        backgroundColor = "#FFFFFF";
                      }
    
                      const numberStyle = {
                        backgroundColor,
                      };
    
                      return (
                        <div
                        onClick={() => {
                          const uniqueId = currentAssessment?.unique_id;
                          if (assessmentData[uniqueId]?.status === 'viewed') {
                            setAssessmentData((prev) => ({
                              ...prev,
                              [uniqueId]: {
                                ...prev[uniqueId],
                                status: 'skipped',
                              },
                            }));
                            if (uniqueId && assessmentData[uniqueId]) {
                              const currentAssmnt = assessment[currentIndex];
                              let resetAnswers;
                              let resetHighlightContent = "";
                              let resetLastSelectedText = "";
                              let resetAcrossClues = [];
                              let resetDownClues = [];
                      
                              if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
                                const filteredLeft = (currentAssmnt.left || []).filter(
                                  (leftData) => leftData.trim() !== ""
                              );
                              const filteredRight = (currentAssmnt.right || []).filter(
                                  (rightData) => rightData.trim() !== ""
                              );
                                  resetAnswers = {
                                      left: filteredLeft,
                                      right: filteredRight,
                                  };
                              } else if (currentAssmnt.type === "Rearrange") {
                                  resetAnswers = currentAssmnt.options || [];
                              } else if (currentAssmnt.type === 'cross word puzzle') {
                                  resetAnswers = currentAssmnt.options.map(row => (
                                      row.map(cell => ({
                                          clueID: cell.clueID || "",
                                          clueNumber: cell.clueNumber,
                                          cluevalue: '',
                                          disabled: cell.cluevalue === '',
                                      }))
                                  ));
                      
                                  resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: ''
                                  }));
                                  resetDownClues = currentAssmnt.right.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: '' 
                                  }));
                              } else if (currentAssmnt.type === "highlight") {
                                resetHighlightContent =currentAssmnt.options.replace(
                                  /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
                                  '$1'
                              ) || ""; 
                              resetHighlightContent =resetHighlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1')|| ""; 
                    
                                resetLastSelectedText = "";
                            } else {
                                  resetAnswers = [];
                              }
                      
                              setAssessmentData((prev) => ({
                                  ...prev,
                                  [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: resetAnswers,
                                      highlightContent: resetHighlightContent,
                                      lastSelectedText: resetLastSelectedText,
                                      cluesAcross: resetAcrossClues,
                                      cluesDown: resetDownClues,
                                  },
                              }));
                              setSelectedOptions((prev) => {
                                const { [uniqueId]: _, ...rest } = prev; 
                                return rest;
                            });
                          }
                          }
                          setCurrentIndex(number - 1);
                          setValidateClicked(false);
                        }}
                          key={number}
                          className={style.number}
                          style={numberStyle}
                        >
                          <p> {number}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
                )}
              </div>
           </div>
           {trialUser&&result?(
           <div className={style.bodyBottom}>
            <div className={style.subscriptionHeader}>
              <h4>You have successfully completed your trial!</h4>
                <span>Ready to take your English to the next level? </span>
                <p>Subscribe now and gain unlimited access to:</p>
                <button onClick={()=>{
                  navigate("/subscribe",{
                    state:{
                      userID:userDetails&&userDetails._id?.$oid,
                      Username:userDetails &&userDetails.Username
                    }
                  })
                }}>Subscribe Now</button>
            </div>
            <div className={style.subscriptionGrid}>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={lessonImage} alt="Lesson"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Comprehensive Lessons:</p>
                  <span> Master every aspect of English with native designed lessons.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={motivationImage} alt="Motivation"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Motivational Milestones: 
                  </p>
                  <span> Celebrate your progress and stay motivated with each milestone.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={questionImage} alt="Question"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Assessment Questionnaires: 
                  </p>
                  <span> Measure your mastery and refine your skills through targeted quizzes.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={timeImage} alt="Time"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Flexible Learning:</p>
                  <span>Study anytime, anywhere, at your own pace.</span>
                </div>
              </div>


            </div>
            <div className={style.subscriptionBottom}>
             
                <p>Subscribe Today and <br/>
                Transform Your English Learning Journey!</p>
                <button onClick={()=>{
                  navigate("/subscribe",{
                    state:{
                      userID:userDetails&&userDetails._id?.$oid,
                      Username:userDetails &&userDetails.Username
                    }
                  })
                }}>Subscribe Now</button>
            </div>
           </div>)
           :
           null
           }
          </div>
        ) : (
          <div className={style.info}><p>No Assessment found</p></div>
        )}
        </>}
      </div>

      {finishPopup?
      <>
      <div className={style.popupOverlay}></div>
        <div className={style.Popup}>
          <div className={style.popupText}>
            <p>Are you sure you want to end the test?</p>
          </div>
          <div className={style.PopupButton}>
          <button
            className={style.noButton}
              onClick={() => {
                setFinishPopup(false);
              }}
            >
              No
            </button>
            <button
            className={style.yesButton}
              onClick={(e) => {
                e.preventDefault();
               endAssessment();
              }}
            >
              Yes
            </button>
           
          </div>
        </div>
      </>:""}
      {failedPopup&&!result&&(
        <div className={style.wrongAnswerPopup}>
         <div className={style.wrongAnswerPopupImage}> <img src={FailedImage} alt="Incorrect answer"/>
         <div className={style.wrongAnswerPopupContent}>
          <p>Every mistake is an opportunity to learn and grow. <br/>
          Keep going!</p>
         </div>
         <div className={style.wrongAnswerPopupClose}>
          <AiOutlineClose onClick={()=>{
            setFailedPopup(false);
            setWrongAnswerCount(0);
          }}/>
         </div>
         </div>
        </div>
      )}
      {achievementPopup &&(
         <>
         <div className={style.popupOverlay}></div>
         <div className={style.achievementPopup}>
         
     <div className={style.achievementPopupSection}>
        <div className={style.achievementPopupHeder}>
          <h2>Hooray!</h2>
          <p>
            You just unlocked <span>{carouselData.length} Achievements</span>
          </p>
        </div>
        <div className={style.achievementCarousel}>
    <div
      className={style.carouselTrackContainer}
      style={{
        overflowX: carouselData.length > 1 ? "auto" : "hidden",
        display: "flex",
        justifyContent: carouselData.length === 1 ? "center" : "flex-start",
      }}
    >
      <div
        className={style.carouselTrack}
        style={{
          transform: "none",
        }}
      >
        {carouselData.map((item, index) => (
          <div key={index} className={style.carouselSlide}>
            <div className={style.achievementPopupImage}>
              <img src={item.active_img} alt={item.name} />
            </div>
            <div className={style.carouselText}>
              <h3>{item.name}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
     </div>

      <div className={style.achievementPopupButton}>
        <button
          onClick={() => {
           setAchievementPopup(false);
           if(points>0||points>"0"){
            setCollectPopup(true)
           }
          
          }}
        >
          Collect
        </button>
      </div>
    </div>
         </>
      )}
      {collectPopup&&(
        <>
        <div className={style.popupOverlay}></div>
        <div className={style.achievementPopup}>
          <div className={style.collectPopupHeader}>
            <p>Congratulations</p>
          </div>
         <div className={style.collectPopupImageContainer}>
            <div className={style.collectPopupImage}>
           <img src={Coins} alt="Reward"/>
            </div>
            <div className={style.collectPopupLottie}>
            <Lottie
              animationData={coinsLottie}
              loop={true}
              duration
              autoplay
            />
            </div>
         </div>
          <div className={style.collectPopupText}>
            <span>You receive</span>
            <h3>{points} xp</h3>
            {/* <p>500 xp for completing a module within 6 weeks</p>
            <p>100 xp for scoring above 85%</p> */}
          </div>
        <div className={style.achievementPopupButton}>
        <button
          onClick={() => {
           setCollectPopup(false);
           const token = userDetails&&userDetails.auth_token; // Assuming `key` is the token
           dispatch(fetchUserDetails({ userid: userDetails&&userDetails._id?.$oid, token })); 
          }}
        >
          Collect All
        </button>
      </div>
        </div>
        </>
      )}
    </div>
  );
};

export default Assessment;
